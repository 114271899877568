import React, { ReactPropTypes } from "react";
import Login from "./banner";
import * as H from "history";

interface Props extends ReactPropTypes {
  history: H.History;
}
function Home(props: Props) {
  const { history } = props;
  return (
    <div className="login-wrap">
      <Login
        title={window.systemConfig.systemName || "数商100 - 后台管理系统"}
        content=""
        bannerImg="homebanner.png"
      />
      <div className="icp-module">
        <a
          style={{ color: "#fff", fontSize: 12 }}
          href="http://beian.miit.gov.cn"
          target="_blank"
        >
          粤ICP备2022077629号
        </a>
      </div>
    </div>
  );
}

export default Home;
